import React from 'react';
import classNames from 'classnames';

import { useResponsive, useTranslation } from 'client/hooks';

import logo from 'client/assets/top/logo.png';
import css from './TopSection.module.scss';

const TopSection: React.FC = () => {
  const [isMobile] = useResponsive('MOBILE');
  const { translate } = useTranslation('topSection');

  return (
    <section className={css.topSection}>
      <div className={css.mobileImages}>
        <div className={css.first}></div>
        <div className={css.second}></div>
      </div>
      <div className={css.info}>
        <div className={css.top}>
          <div className={css.logoWrapper}>
            <img src={logo} alt="logo" />
          </div>
          <div className={css.text}>
            <span className={css.black} dangerouslySetInnerHTML={{ __html: translate('titleBlack') }}></span>
            <span className={css.red} dangerouslySetInnerHTML={{ __html: translate('titleRed') }}></span>
          </div>
        </div>

        <p className={classNames(css.paragraphFirst)} dangerouslySetInnerHTML={{ __html: translate('paragraph1') }}></p>
        <p
          className={classNames(css.paragraphSecond)}
          dangerouslySetInnerHTML={{ __html: isMobile ? translate('paragraph2mobile') : translate('paragraph2') }}
        ></p>
        <p className={classNames(css.paragraphThird)} dangerouslySetInnerHTML={{ __html: translate('paragraph3') }}></p>
        <p className={classNames(css.paragraphForth)} dangerouslySetInnerHTML={{ __html: translate('paragraph4') }}></p>
      </div>
    </section>
  );
};

export default TopSection;
