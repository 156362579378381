import * as React from 'react';
import classNames from 'classnames';
import { useField } from 'formik';
import { useTranslation } from 'client/hooks';
import css from './TextFieldInput.module.scss';

interface Props {
  classname?: string;
  name?: string;
  placeholder?: string;
  onChange?: (evt: any) => void;
  type?: string;
  pattern?: RegExp;
  isRequired?: boolean;
}

const TextFieldInput: React.FC<Props> = (props) => {
  const {
    classname = '',
    name = '',
    placeholder = '',
    onChange,
    type = '',
    pattern,
    isRequired = false,
    ...rest
  } = props;
  const [field, meta, helpers] = useField(name);
  const { translate } = useTranslation('errors');

  const onInputChange = (e: any) => {
    if (pattern) {
      if (e.target.value === '' || pattern.test(e.target.value.toLocaleLowerCase())) {
        helpers.setValue(e.target.value);
      }
    } else {
      helpers.setValue(e.target.value);
    }
  };

  return (
    <div className={css.container}>
      <input
        className={classNames(css.formInput, classname)}
        placeholder={placeholder}
        {...field}
        type={type}
        onChange={onInputChange}
      />
      <span>{meta.error && meta.touched ? translate(`${name}.${meta.error}`) : ''}</span>
      {isRequired && <span className={css.requiredStar}>*</span>}
    </div>
  );
};

export default TextFieldInput;
