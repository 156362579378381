import React from 'react';
import { useField } from 'formik';
import cn from 'classnames';
import _ from 'lodash';
import { useTranslation } from 'client/hooks';

import css from './TextArea.module.scss';

const FIELD_LIMIT = 1500;

interface Props {
  classname?: string;
  name?: string;
  placeholder?: string;
  maxWords?: number;
  isRequired?: boolean;
}

const TextArea: React.FC<Props> = (props) => {
  const { classname = '', name = '', placeholder = '', maxWords, isRequired = false } = props;
  const { translate } = useTranslation('errors');
  const [field, meta, helpers] = useField(name);

  const wordsAmount = getWordsAmount(field.value || '');

  const processTextChange = (text: string) => {
    const newAmount = getWordsAmount(text);

    if (maxWords && newAmount > maxWords) {
      const splittedWords = text.split(' ');
      helpers.setValue(splittedWords.slice(0, maxWords).join(' '));
      return;
    }

    helpers.setValue(text);
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    processTextChange(e.target.value);
  };

  const onPaste = (e: React.ClipboardEvent<HTMLTextAreaElement>) => {
    // check if words limit is already exceeded:
    if (maxWords && getWordsAmount(field.value || '') > maxWords) {
      e.preventDefault();
      return;
    }
  };

  return (
    <div className={css.container}>
      <textarea
        {...field}
        onChange={handleOnChange}
        onPaste={onPaste}
        className={cn(css.textarea, classname)}
        rows={4}
        cols={50}
        placeholder={placeholder}
      />
      {maxWords && (
        <span className={css.wordsCount}>
          {maxWords} / {wordsAmount}
        </span>
      )}
      <span className={css.error}>{meta.error && meta.touched && translate(`${name}.${meta.error}`)}</span>
      {isRequired && <span className={css.requiredStar}>*</span>}
    </div>
  );
};

function getWordsAmount(text: string) {
  return text.split(' ').filter((s) => !!s.trim()).length ?? 0;
}

export default TextArea;
