import React from 'react';
import { Formik, Form, FormikProps } from 'formik';
import classNames from 'classnames';
import _ from 'lodash';

import { useAppDispatch, useTranslation } from 'client/hooks';
import { leadApi } from 'client/redux/api/lead';
import { updateParamsSchema } from 'endpoints/lead';
import { REG_EXP } from 'client/constants';
import { CONFIG } from 'constants/index';

import TextFieldInput from '../TextFieldInput';
import TextArea from '../TextArea';
import Button from '../Button';
import Checkbox from '../Checkbox';
import { dataLayerEvents } from 'client/utils/gtag';
import { setPopUp } from 'client/redux/ui';

import css from './Form.module.scss';

interface Props {
  className?: string;
}

type FormData = {
  fullName: string;
  email: string;
  phone: string;
  birthYear: string;
  description: string;
  confirmation: boolean;
};

const formValues: FormData = {
  fullName: '',
  email: '',
  phone: '',
  birthYear: '',
  description: '',
  confirmation: false,
};

let createLeadAttemptsCount = 0;
let sendValues: FormData | null = null;

const FormMain: React.FC<Props> = () => {
  // const [isLoading, setIsLoading] = useState<boolean>(false);
  const { translate } = useTranslation('form');
  const [createLead, createLeadResult] = leadApi.endpoints.createLead.useMutation();
  const formikRef = React.useRef<FormikProps<FormData>>(null);
  const dispatch = useAppDispatch();
  const dataLayerInstance = new dataLayerEvents();

  React.useEffect(() => {
    if (
      (createLeadResult.status === 'fulfilled' && !createLeadResult.data?.success) ||
      createLeadResult.status === 'rejected'
    ) {
      if (createLeadAttemptsCount < 3) {
        if (sendValues) createLead(sendValues);
        createLeadAttemptsCount++;
      } else {
        sendValues = null;
        createLeadAttemptsCount = 0;
      }
    }

    if (createLeadResult.data?.success) {
      dataLayerInstance.onFormSent(CONFIG.domainUrl);
      sendValues = null;
      createLeadAttemptsCount = 0;
    }
  }, [createLeadResult, dispatch]);

  const handleSubmit = async (values: FormData) => {
    createLead({ ...values });
    sendValues = { ...values };
    createLeadAttemptsCount++;
    setTimeout(() => {
      dispatch(setPopUp('success'));
      const formik = formikRef.current;
      formik?.resetForm({ values: _.cloneDeep(formValues) });
    }, 1500);
  };

  return (
    <div className={css.formWrapper}>
      <div className={css.form}>
        <Formik
          innerRef={formikRef}
          initialValues={_.cloneDeep(formValues)}
          onSubmit={handleSubmit}
          validationSchema={updateParamsSchema}
          // validateOnChange={true}
          validateOnBlur={true}
        >
          {({ ...rest }) => {
            return (
              <Form>
                <div className={classNames(css.fieldsWrapper)}>
                  <div className={classNames(css.fieldWrapper, css.short)}>
                    <TextFieldInput
                      name="fullName"
                      placeholder={translate('textFields.fullName')}
                      pattern={REG_EXP.HEBREW_ONLY_LETTERS}
                      isRequired
                    />
                  </div>
                  <div className={classNames(css.fieldWrapper, css.short)}>
                    <TextFieldInput
                      name="birthYear"
                      placeholder={translate('textFields.birthYear')}
                      pattern={REG_EXP.NUMBERS}
                      isRequired
                    />
                  </div>
                  <div className={classNames(css.fieldWrapper, css.short)}>
                    <TextFieldInput
                      name="email"
                      placeholder={translate('textFields.email')}
                      pattern={REG_EXP.EMAIL}
                      isRequired
                    />
                  </div>
                  <div className={classNames(css.fieldWrapper, css.short)}>
                    <TextFieldInput
                      name="phone"
                      placeholder={translate('textFields.phone')}
                      pattern={REG_EXP.NUMBERS}
                      isRequired
                    />
                  </div>
                </div>
                <div className={classNames(css.fieldsWrapper)}>
                  <div className={classNames(css.fieldWrapper)}>
                    <TextArea
                      name="description"
                      placeholder={translate('textFields.description')}
                      maxWords={350}
                      isRequired
                    />
                  </div>
                </div>

                <div className={classNames(css.submitWrapper)}>
                  <Checkbox name="confirmation" label={translate('confirmation')} className={css.confirmation} />

                  <Button
                    type="submit"
                    disabled={rest.isSubmitting}
                    className={css.submitButton}
                    text={translate('submitButton')}
                  />
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default FormMain;
