import React from 'react';
import cn from 'classnames';

import Header from 'client/components/common/Header';
import TopSection from 'client/components/common/TopSection';
import FormMain from 'client/components/common/Form';
import Footer from 'client/components/common/Footer';

import css from './Intro.module.scss';

interface Props {
  className?: string;
}

const Main: React.FC<Props> = (props) => {
  const { className = '' } = props;

  return (
    <div className={cn(css.main, className)}>
      <div className={css.desktopImages}>
        <div className={css.left}></div>
        <div className={css.right}></div>
      </div>
      <Header />
      <TopSection />
      <FormMain />
      <Footer />
    </div>
  );
};

export default Main;
